<template>
  <div class="backgroundCol">
    <b-container>
      <b-row>
        <b-col lg="9" md="12" cols="12" class="newsFont">
          <b-row class="pad wholePage">
            <b-col>
              <h2
                :class="{ developGreenText: develop === config.VUE_APP_ID_ADT }"
                class="newsTitle"
                v-html="postInfo[0].title.rendered"
              ></h2>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col>
              <b-img
                class="featureImg"
                :src="postInfo[0].better_featured_image.source_url"
              ></b-img>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col>
              <p class="publishedData">Published {{ dateTime }}</p>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col class="social">
              <ShareNetwork
                network="facebook"
                :url="'https://asiantour.com/adt/' + id"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['fab', 'facebook-square']"
                />
              </ShareNetwork>
            </b-col>
            <b-col class="social">
              <ShareNetwork
                network="facebook"
                :url="'https://asiantour.com/adt/' + id"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <font-awesome-icon class="icon" :icon="['fab', 'twitter']" />
              </ShareNetwork>
            </b-col>
            <b-col class="social">
              <ShareNetwork
                network="facebook"
                :url="'https://asiantour.com/adt/' + id"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
              </ShareNetwork>
            </b-col>
            <b-col class="social">
              <ShareNetwork
                network="linkedin"
                :url="'https://asiantour.com/adt/' + id"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <font-awesome-icon class="icon" :icon="['fab', 'linkedin']" />
              </ShareNetwork>
            </b-col>
            <b-col class="col-md-8"> </b-col>
          </b-row>
          <b-row class="pad">
            <b-col>
              <span v-html="postInfo[0].content.rendered"></span>
              <b-button onclick="window.print()" class="print"
                ><font-awesome-icon :icon="['fa', 'print']" /> Print</b-button
              >
              <b-nav-item class="back"
                ><font-awesome-icon :icon="['fa', 'arrow-left']" /> Back to News
              </b-nav-item>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" md="12" cols="12" class="col-md-3">
          <b-row>
            <b-col>
              <Newssidebar :develop="develop" :config="config" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Newssidebar from "@/components/newsSidebar.vue";
import axios from "axios";
export default {
  name: "postFull",
  props: ["develop", "config", "id"],
  components: {
    Newssidebar
  },
  data() {
    return {
      postInfo: []
    };
  },
  computed: {
    dateTime: function() {
      var dateOG = this.postInfo[0].date.split("T");
      var splitDate = dateOG[0].split("-");
      var newDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
      var time = dateOG[1];
      return newDate + " - " + time;
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WP_ADT_API_URL + "wp/v2/posts?slug=" + this.id)
      .then(response => (this.postInfo = response.data));
  }
};
</script>

<style scoped>
::v-deep .wp-caption.alignnone {
  width: 100% !important;
}
::v-deep .wp-caption.aligncenter {
  width: 100% !important;
}
::v-deep .wp-caption img {
  border: 0 none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}
::v-deep img.alignnone.wp-image-16577.size-large {
  width: 100%;
  height: auto;
}
::v-deep .wp-caption-text {
  line-height: 1.5;
  font-size: 10px;
  margin: 0;
  text-align: center;
  margin-bottom: 2em;
}
.social {
  width: 20px;
  max-width: 50px;
}
::v-deep .size-large {
  width: 100%;
  height: auto;
}
p.publishedData {
  font-weight: bold;
  margin-top: 5em;
}
.newsFont {
  font-size: 14px;
}
h2.newsTitle {
  color: #0a3f7e;
}
.backgroundCol {
  background-color: #f1f1f1;
}
.featureImg {
  width: 100%;
}
.icon {
  font-size: 34px;
}
.pad {
  padding-top: 2%;
}
.wholePage {
  padding-bottom: 6%;
  margin-top: 3em;
}
::v-deep .logo {
  margin-top: 3.6em;
}
.developGreenText {
  color: #76c3bd !important;
}
.print {
  color: #b02026;
  background: transparent;
  border: 0;
  padding-left: 0;
}
li {
  list-style: none;
}
.back {
  font-size: 13pt;
  padding-bottom: 20px;
}
.back > a {
  padding-left: 0;
}
</style>
